import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer bg-theme-DarkColor bg-img3 clearfix" /*style={{ backgroundImage: 'url("images/bg-image/row-bgimage-3.png")' }}*/>
                <div className="first-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget_text clearfix">
                                <h3 className="widget-title">Information</h3>
                                    <p>JJT Cleaning Services is a cleaning company able to provide daily, weekly, monthly or annual cleaning services as needed through all 5 boroughs, New York
                                    counties, New Jersey and Connecticut.</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget-timing clearfix">
                                    <h3 className="widget-title">Contact info</h3>
                                    <p>102-44 90th Ave, Richmond Hill <br></br> New York, 11418</p>
                                    <h3><a href="tel:9173311057">(917) 331-1057</a></h3>
                                    <h3><a href="tel:3474222833">(347) 422-2833</a></h3>
                                    <p><a href="emailto:jjtcleaningservices@gmail.com">jjtcleaningservices@gmail.com</a></p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget_nav_menu clearfix">
                                    <h3 className="widget-title">Services</h3>
                                    <ul className="menu">
                                        <li><p>Post Construction Clean-up</p></li>
                                        <li><p>Power Washing</p></li>
                                        <li><p>Stripping & Waxing</p></li>
                                        <li><p>Building Maintenance</p></li>
                                        <li><p>Flood Damage</p></li>
                                        <li><p>Debris Removal</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="footer-nav-menu">
                                    <li><a href={process.env.PUBLIC_URL + '/'}>About Us</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Services</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Privacy Policy</a></li>
                                </ul>
                                <span className="copyright">Copyright © 2024 <a href={process.env.PUBLIC_URL + '/'}>JJT Cleaning Services Contractors </a> All rights reserved.				
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


