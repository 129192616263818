import React, { Component } from 'react'
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import Logo from './Logo'


export class Header extends Component {
    
    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.isSticky);
    }

    scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    }

    isSticky = (e)=>{
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
    };

    render() {        
        return (
                
            <header id="masthead" className="header ttm-header-style-01">
                {/* ttm-topbar-wrapper */}
                <div className="ttm-topbar-wrapper bg-theme-DarkColor text-theme-WhiteColor clearfix">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-5">
                                <ul className="top-contact text-left">
                                    <li>24 hours assistance call <a className="number-header" href="tel:3474222833">Jose (347) 422-2833</a> | <a className="number-header" href="tel:9173311057">Juan (917) 331-1057</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-7">
                                <div className="topbar-right text-right d-flex justify-content-end">
                                    <ul className="top-contact">
                                        <li><i className="far fa-clock" />&nbsp;&nbsp;Working hours: 06:00 AM to 20:00 PM </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ttm-topbar-wrapper end */}
                {/* site-header-menu */}
                <div id="site-header-menu" className="site-header-menu bg-theme-WhiteColor">
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* site-navigation */}
                                    <div className="site-navigation d-flex flex-row align-items-center justify-content-between">
                                        {/* site-branding */}
                                        <div className="site-branding">
                                            <Logo/>
                                        </div>
                                        {/* site-branding end */}
                                        <div className="border-box-block">
                                            <div className=" d-flex align-items-center">
                                                {/* menu */}
                                                <Menu/>
                                                <div className="mobile-menu"><MobileMenu/></div>
                                                {/* menu end */}
                                                <div className="ttm-widget_header">
                                                    <div className="widget_info d-flex flex-row align-items-center justify-content-end pl-50">
                                                    </div>
                                                    <div className="header_btn">
                                                        <a href={process.env.PUBLIC_URL + '/Contact'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-5"> Contact Us </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* site-navigation end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* site-header-menu end */}
            </header> 
        )
    }
}

export default Header;