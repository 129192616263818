import React, { Component } from "react";
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBHamburgerToggler } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

export class MobileMenu extends Component {
  state = {
    collapse1: false,
    collapseID: ''
  }
  
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }
  
  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }

  scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ behavior: "smooth" });
    }
  }
  
  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
            <MDBCollapse isOpen={this.state.collapse1} navbar>
              <MDBNavbarNav left>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBNavLink to={process.env.PUBLIC_URL + '/'}>Home</MDBNavLink>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBNavLink to={ process.env.PUBLIC_URL + '/#about' }>About Us</MDBNavLink>
                    </MDBDropdown>
                </MDBNavItem> 
                <MDBNavItem>
                  <MDBDropdown>
                      <MDBNavLink to={ process.env.PUBLIC_URL + '/#services' }>Services</MDBNavLink>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                        <span onClick={() => this.scrollToSection("/#our-work")}>Our Work</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBNavLink to={process.env.PUBLIC_URL + '/Portfolio'}>Portfolio</MDBNavLink>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBNavLink to={process.env.PUBLIC_URL + '/Contact'}> Contact Us </MDBNavLink>
                    </MDBDropdown>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
        </MDBNavbar>
      </Router>
    )
  }
}

export default MobileMenu;