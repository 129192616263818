import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: '',
        message: ''
    });

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [capVal, setCapVal] = useState(null)

    const formatPhoneNumber = (value) => {
        const phoneNumber = value.replace(/\D/g, '');

        const formattedPhoneNumber = phoneNumber.slice(0, 10);
        const formatted = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

        return formatted;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://back.jjt-cleaningservices.com:3000/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setShowSuccessMessage(true);
            } else {
                setShowErrorMessage(true);
            }
        } catch (error) {
            setShowErrorMessage(true);
        }
    };

    const handleChange = (e) => {
        const value = e.target.name === 'phone' ? formatPhoneNumber(e.target.value) : e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        });
    };

    return (
        <form id="contact_form" className="login_form wrap-form" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <label htmlFor="name"> Your Name *
                        <input type="text" id="name" name="name" placeholder="John Doe" onChange={handleChange} required/>
                    </label>
                </div>
                <div className="col-lg-6">
                    <label htmlFor="email"> Email Address *
                        <input type="email" id="email" name="email" placeholder="example@gmail.com" onChange={handleChange} required/>
                    </label>
                </div>
                <div className="col-lg-6">
                    <label htmlFor="phone"> Phone Number *
                        <input type="tel" id="phone" name="phone" placeholder="(347) 111-0101" value={formData.phone} onChange={handleChange} required/>
                    </label>
                </div>
                <div className="col-lg-6">
                    <label htmlFor="service">Requested service *
                        <select id="service" name="service" className="custom-select-box pl-0" onChange={handleChange} required>
                            <option>Flood Damage</option>
                            <option>Debris Removal</option>
                            <option>Power Washing</option>
                            <option>Stripping & Waxing</option>
                            <option>Building Maintenance</option>
                            <option>Post Construction Clean-Up</option>
                            <option>Retail</option>
                        </select>
                    </label>
                </div>
                <div className="col-lg-12">
                    <label>
                        <textarea name="message" rows="4" placeholder="Your message" onChange={handleChange} required></textarea>
                    </label>
                </div>
                <div className="col-md-12">
                    <div className="text-left">
                        <ReCAPTCHA 
                        sitekey="6Lc6z8EpAAAAAEPlVjRLcw7QWrgdt8kVyHe_R1Zr"
                        onChange={(val) => setCapVal(val)}/>
                        <button type="submit" id="submit" className="mt-10 submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" value disabled={!capVal}>
                            Submit Request
                        </button>
                        {showSuccessMessage && (
                            <p><i className="fa fa-check" /> Request successfully sent.</p>
                        )}
                        {showErrorMessage && (
                            <p><i className="fa fa-times" /> An error occurred. Please try again later.</p>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;