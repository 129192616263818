import React, { Component } from 'react';
import Header from '../components/layout/Header';
import { Footer } from '../components/layout/Footer';
import { Tabs, TabPanel } from 'react-tabs';
import ContactForm from '../components/layout/ContactForm';

export class Contact extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />

                <section className="ttm-row padding_zero-section clearfix">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="map">
                                    <iframe height="675" width="100%" title="JJT Cleaning Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.147955582059!2d-73.8424552!3d40.692738999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25dff7a2d1ba3%3A0x2bead228175ff81a!2s102-44%2090th%20Ave%2C%20Jamaica%2C%20NY%2011418%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sve!4v1713706225140!5m2!1ses-419!2sve" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title text-center">
                                    <div className="title-header">
                                        <h2 className="title">Contact <span className="webkit-content">[Us]</span></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>You need to offer something that makes you unique. You need to do something
                                            to make every customer experience remarkable,</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 align-self-center mt_140">
                                <span className="ttm-call-to-arrow-1">
                                    <h3>Get a free <br></br> estimate</h3>
                                    <img className="img-fluid" src="images/arrow-text.png" alt="product-icon" />
                                </span>
                                <div className="ttm-tabs ttm-tab-style-02">
                                    <Tabs>
                                        <div className="content-tab">
                                            <TabPanel>
                                                <ContactForm />
                                            </TabPanel>
                                        </div>
                                    </Tabs>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="">
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-location"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Our location</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/102-44+90th+Ave,+Jamaica,+NY+11418,+EE.+UU./@40.692739,-73.8424552,17z/data=!4m6!3m5!1s0x89c25dff7a2d1ba3:0x2bead228175ff81a!8m2!3d40.692739!4d-73.8424552!16s%2Fg%2F11crtzb1q9?entry=ttu">102-44 90th Ave, Richmond Hill, New York, 11418</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Email us</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <a className="d-block" href="mailto:jjtcleaningservices@gmail.com">jjtcleaningservices@gmail.com</a>
                                                <a href="/">www.jjt-cleaningservices.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-operator"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Phone number</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <a href="tel:9173311057"> Juan: (917) 331-1057</a>
                                                <br />
                                                <a href="tel:3474222833"> Jose: (347) 422-2833</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row padding_top_zero-section marque-section overflow-hidden">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 ttm-marquebox-wrapper">
                                <ul className="marquebox-list">
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Retail</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Power Washing</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Office Cleaning</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Flood Damage</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Debris Removal</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Stripping & Waxing</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Building Maintenance</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Post Construction Clean-Ups</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}

export default Contact;