import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

export class Menu extends Component {
    state = {
        show: false,
    }
    scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    }
    
    render() {
        return (
            <Router>
                <nav className="main-menu">         
                    <ul className="menu">
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/'} className="mega-menu-link">Home</a>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/#about'} className="mega-menu-link">About</a>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/#services'} className="mega-menu-link">Services</a>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/#our-work'} className="mega-menu-link">Our Work</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/Portfolio'}>Portfolio</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </Router>
        )
        
    }
}

export default Menu;