import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import { Banner } from '../components/banner/Home1_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import LocationMap from '../components/layout/LocationMap';
import ContactForm from '../components/layout/ContactForm';

export class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }
    render() {
        var slick_slider = {
            dots: false,
            arrows: false,
            autoplay: true,
            infinite: true,
            speed: 200,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };

        return (
            <div className="site-main">

                <Header />

                {/* Banner */}
                <Banner />
                {/* Banner end */}

                <section id="about" className="ttm-row clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>About Us!</h3>
                                        <h2 className="title">Our experts always deliver the <span className="webkit-content">[Clean]</span></h2>
                                    </div>
                                </div>
                                <div className="ttm-fid-view-righticon style4">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon-washing-clothes"></i>
                                    </div>
                                    <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                        <h4 className="ttm-fid-inner">
                                            +<CountUp start={0} end={25} duration={5} />
                                        </h4>
                                        <h3 className="ttm-fid-title">Years of experience</h3>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-40"></div>
                                </div>
                                <ul className="ttm-list style1">
                                    <li>
                                        <i className="fa fa-check"></i>
                                        <span className="ttm-list-li-content">We're  fully insured</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        <span className="ttm-list-li-content">Free estimates</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        <span className="ttm-list-li-content">24/7 Support</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-5">
                                <div className="pl-50 pl-xl-0">
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-mission"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Our mission</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Providing customers with world-class cleaning services providing superior quality with excellent results. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-focus"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Our vision & values</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Provide the highest quality cleaning services with focus, integrity and discipline.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-history"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Our Story</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 25 years of experience in cleaning services.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="services" className="ttm-row bottom-zero-padding-section fid-section bg-theme-DarkColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-2.png")' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text mb-0">
                                    <div className="title-header">
                                        <h2 className="title">Discover our  <span className='webkit-content'>[Services]</span></h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                        <div className="separator">
                            <div className="sep-line mt-4 mb-4" />
                        </div>
                        <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} slidesToShow={3} arrows={true}
                            autoplay={true} responsive={[{ breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                            { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 } },
                            { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/stripping.jpg" alt="floor stripping" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">
                                                <h3>
                                                    <p>Stripping & Waxing</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/building-maintenance.jpg" alt="building maintenance" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">
                                                <h3>
                                                    <p>Building Maintenance</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/retail.jpg" alt="retail" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">
                                                <h3>
                                                    <p>Retail</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/power-washing.jpg" alt="power washing" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">{/* featured-title */}
                                                <h3>
                                                    <p>Power Washing</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/flood-damage.jpg" alt="flood damage" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">{/* featured-title */}
                                                <h3>
                                                    <p>Flood Damage</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/debris-removal.jpg" alt="debris removal" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">
                                                <h3>
                                                    <p>Debris Removal</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/office-cleaning.jpg" alt="general offices cleaning" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">
                                                <h3>
                                                    <p>General Offices</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-portfolio style1">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/services/debris-removal.jpg" alt="general offices cleaning" />
                                    </div>{/* featured-thumbnail end*/}
                                    {/* ttm-box-view-overlay */}
                                    <div className="ttm-box-view-overlay">
                                        <div className="featured-content">
                                            <div className="featured-title text-center">
                                                <h3>
                                                    <p>Post Construction Cleaning</p>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </section>

                <section id="contact" className="ttm-row padding_bottom_zero-section clearfix">
                    <div className="container">
                        <div className="row box-shadow p-70 border-radius-8 bg-theme-WhiteColor g-0 pl-lg-15 pr-lg-15">
                            <div className="col-lg-6 align-self-center mt_90">
                                <span className="ttm-call-to-arrow">
                                    <h3>We've free <br /> estimates</h3>
                                    <img className="img-fluid" src="images/arrow-text.png" alt="product-icon" />
                                </span>
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>Get in touch!</h3>
                                        <h2 className="title">Lets talk with <span className="webkit-content">[Expertise]</span></h2>
                                    </div>
                                </div>
                                <div className="featured-icon-box icon-align-before-title style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            <i className="flaticon-support"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p>Get in touch with us quickly by filling this form with your details and serviced needed..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ttm-tabs ttm-tab-style-02 border-left pl-75 border-left-0 pl-lg-0">
                                    <Tabs>
                                        <TabList className="tabs">
                                            <Tab className="tab">
                                                <i className="flaticon flaticon-research"></i>
                                                <h3>Request an estimate</h3>
                                            </Tab>
                                        </TabList>
                                        <div className="content-tab">
                                            <TabPanel>
                                                <ContactForm />
                                            </TabPanel>
                                        </div>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* padding_zero-section */}
                <section className="ttm-row testimonial-section bg-theme-GreyColor pb-100 bg-img1 mt-lg-0 mt_100 clearfix ttm-boxes-spacing-30px" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                    <div className="container-fluid">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 pl-80 pr-80 pl-lg-15 pr-lg-15">
                                <div className="section-title title-style-center_text mb-0">
                                    <div className="title-header">
                                        <h2 className="title">What customers <span className="webkit-content">[Say ?]</span></h2>
                                    </div>
                                </div>
                                <Slider className="row slick_slider slick-arrows-style4 ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={3} arrows={true}
                                    autoplay={false} responsive={[{ breakpoint: 1199, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                                    { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } },
                                    { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                                    <div className="col-lg-12">
                                        <div className="testimonials style1 text-center mt-lg-30">
                                            <div className="testimonial-content">{/* testimonials-content */}
                                                <blockquote>They came and did an excellent job washing the windows in my apartment. Juan and his
                                                    co-worker were outstanding. Highly recommended!</blockquote>
                                                <div className="testimonial-avatar">
                                                    <div className="testimonial-img">{/* testimonials-img */}
                                                        <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" alt="testimonial" />
                                                    </div>
                                                </div>
                                                <div className="testimonial-caption">
                                                    <label>Clare G.</label>
                                                    <p>Windows Cleaning</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="testimonials style1 text-center mt-lg-30">
                                            <div className="testimonial-content">{/* testimonials-content */}
                                                <blockquote>We were in a really tight spot and Angel came through for us we couldn't have
                                                    made it through the process without
                                                    Angel's help. He is truly the best!</blockquote>
                                                <div className="testimonial-avatar">
                                                    <div className="testimonial-img">{/* testimonials-img */}
                                                        <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" alt="testimonial"/>
                                                    </div>
                                                </div>
                                                <div className="testimonial-caption">
                                                    <label>Remi F.</label>
                                                    <p>Janitorial Service</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="testimonials style1 text-center mt-lg-30">
                                            <div className="testimonial-content">{/* testimonials-content */}
                                                <blockquote>I hired this company to do a deep clean on my apt after some construction work
                                                    and they did spectacular work! I wouldn't hesitate to hire them again.</blockquote>
                                                <div className="testimonial-avatar">
                                                    <div className="testimonial-img">{/* testimonials-img */}
                                                        <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" alt="testimonial"/>
                                                    </div>
                                                </div>
                                                <div className="testimonial-caption">
                                                    <label>Erika V.</label>
                                                    <p>Post-Construction Clean Up</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row padding_zero-section">
                    <div className="row g-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-left-span bg-theme-DarkColor mt_15">
                                <div className="layer-content">
                                    <div className="text-center">
                                        <div className="featured-imagebox featured-imagebox-service text-md-start style4">
                                            <h2>Location & coverage<span className="webkit-content"> [Area]</span></h2>
                                            <div className="map-location">
                                                <img src="./images/icons/point-location.svg" alt="Area Icon"/>
                                                <h3>We are available to provide our service throughout all 5 <br></br>
                                                    boroughs, New York State counties, New Jersey  and Connecticut</h3>
                                                <img src="./images/icons/location.svg" alt="Location Icon"/>
                                                <h3>102-44 90th Ave, Richmond Hill
                                                <br></br> NY 11418</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <LocationMap />
                        </div>
                    </div>
                </section>

                <section className="ttm-row padding_top_zero-section marque-section ">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 ttm-marquebox-wrapper">
                                <ul className="marquebox-list">
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Retail</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Power Washing</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">General Offices</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Flood Damage</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Debris Removal</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Stripping & Waxing</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Building Maintenance</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Post Construction Clean-Ups</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="our-work" className="ttm-row portfolio-section bg-theme-DarkColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-2.png")' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title title-style-center_text">
                                    <div className="title-header ">
                                        <h2 className="title">Completed <span className="webkit-content">[Projects]</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p-0">
                        <Slider className="row g-0 mt-60 slick_slider ttm-boxes-spacing-30px mt-md-0" {...slick_slider} slidesToShow={4} arrows={false}
                            autoplay={true} responsive={[{ breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
                            { breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 1 } },
                            { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } },
                            { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src="images/portfolio/slider/cleaning-windows.jpg" alt="windows cleaning" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Windows cleaning</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Cleaning building windows.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src="images/portfolio/slider/kid-games.jpg" alt="cleaning service" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Kid Games</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>General cleaning.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src="images/portfolio/slider/autozone.jpg" alt="autozone store clean" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Autozone</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Deep cleaning.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src="images/portfolio/slider/chanel-cleaning.jpg" alt="chanel store cleaning" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Chanel Store</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>General store cleaning.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src="images/portfolio/slider/popeyes.jpg" alt="popeyes store clean" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Popeyes</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Cleaning waxing and more.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src="images/portfolio/slider/gym1-clean.jpg" alt="clean gym" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Gym cleaning</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Stripping, waxing and clean-up.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src="images/portfolio/slider/restaurant-clean1.jpg" alt="restaurant-cleaned" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Restaurant Cleaning</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Stripping, waxing and deep cleaning at the kitchen</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </section>

                <section className="ttm-row client-section bg-theme-GreyColor clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-12 text-center">
                                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={6}
                                    autoplay={true} speed={2000} autoplaySpeed={2000} infinite={true} cssEase='linear' arrows={false} responsive={[{ breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
                                    { breakpoint: 767, settings: { slidesToShow: 3, slidesToScroll: 1 } },
                                    { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                                    <div className="ttm-box-col-wrapper">
                                        <div className="client-box ttm-box-view-boxed-logo">
                                            <a className="client" href="https://autobuilders.net" target="_blank" rel="noreferrer">
                                                <div className="ttm-client-logo-tooltip">
                                                    <img src="./images/client/autobuilders-logo.png" alt="autobuilders logo" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ttm-box-col-wrapper">
                                        <div className="client-box ttm-box-view-boxed-logo">
                                            <a className="client" href="https://adaptcommunitynetwork.org" target="_blank" rel="noreferrer">
                                                <div className="ttm-client-logo-tooltip">
                                                    <img src="./images/client/Adapt-logo.png" alt="adapt community networks logo" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ttm-box-col-wrapper">
                                        <div className="client-box ttm-box-view-boxed-logo">
                                            <a className="client" href="https://berksconstructiongroup.com" target="_blank" rel="noreferrer">
                                                <div className="ttm-client-logo-tooltip">
                                                    <img src="./images/client/BCG-Final-Logo.png" alt="berks construction group logo" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ttm-box-col-wrapper">
                                        <div className="client-box ttm-box-view-boxed-logo">
                                            <a className="client" href="https://conboy-mannion.com" target="_blank" rel="noreferrer">
                                                <div className="ttm-client-logo-tooltip">
                                                    <img src="./images/client/conboy-logo.png" alt="conboy & mannion contracting logo" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ttm-box-col-wrapper">
                                        <div className="client-box ttm-box-view-boxed-logo">
                                            <a className="client" href="https://sciame.com" target="_blank" rel="noreferrer">
                                                <div className="ttm-client-logo-tooltip">
                                                    <img src="./images/client/sciame.png" alt="sciame homes logo" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ttm-box-col-wrapper">
                                        <div className="client-box ttm-box-view-boxed-logo">
                                            <a className="client" href="https://trcgc.net/" target="_blank" rel="noreferrer">
                                                <div className="ttm-client-logo-tooltip">
                                                    <img src="./images/client/trgc.png" alt="trcgc logo" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ttm-box-col-wrapper">
                                        <div className="client-box ttm-box-view-boxed-logo">
                                            <a className="client" href="https://viaconinc.com/" target="_blank" rel="noreferrer">
                                                <div className="ttm-client-logo-tooltip">
                                                    <img src="./images/client/viacon.png" alt="viaconinc logo" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </div >
        )
    }
}


export default Home;
