import React from "react";

const WhatsAppButton = () => {
    return (
        <div className="whatsapp-container">
            <img className="whatsapp-icon" src="./images/whats-up.png" alt="Contact Button" />
            <div className="wsp-options">
                <a className="wsp-option" href="https://wa.me/+13474222833">Talk Jose</a>
                <a className="wsp-option" href="https://wa.me/+19173311057">Talk Juan</a>
            </div>
        </div>
    );
};

export default WhatsAppButton;