import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';

import Home from './pages/Home';
import ScrollToTop from './components/layout/Gotop';
import WhatsAppButton from './components/layout/Ws_button';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';

function App() {
 
  return (
    <div className="page">
      <Router>
        <Switch>
          <Route exact path={'/'} component={ Home } />
          <Route exact path={'/Portfolio'} component={ Portfolio } />
          <Route exact path={'/Contact'} component={ Contact } />
        </Switch> 
          <WhatsAppButton />
          <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;


