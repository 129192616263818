import React, { Component } from "react";
import Slider from 'react-slick';


export class Banner extends Component {
    state = {
        show: false,
      }
      render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
  
            breakpoint: 1199,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
               
        return (
                      
            <Slider className="slick_slider banner_slider banner_slider_1" {...slick_slider} slidesToShow={1} autoplay={false} >
              <div className="slide">
                <div className="slide_img" style={{ backgroundImage: 'url(./images/slider/slider-bg-0.jpg' }}></div>
                <div className="slide__content">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 g-0">
                        <div className="slide__content--headings d-block text-md-start text-center">
                          <div className="padding_top10 padding_bottom10">
                          <h2  data-animation="fadeInDown" className="text-md-end text-theme-WhiteColor"> Need help</h2>
                            <h2  data-animation="fadeInDown" className="text-md-end text-theme-WhiteColor"> with<span className="webkit-content"> [Cleaning?]</span> </h2>
                              <div className="text-md-end banner-btn" data-animation="fadeInUp" data-delay="1.4">
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-25" href={process.env.PUBLIC_URL + '/Contact'}>GET STARTED</a> 
                                <div className="ttm-icon ttm-icon_element-size-md ttm-icon_element-color-skincolor mb-0 text-theme-WhiteColor mt-30 ml-15">
                                  <i className="flaticon-phone-call"></i>
                                  <div className="slide-icon-content">
                                    <h3>
                                        <a href="tel:9173311057">9173311057</a>
                                    </h3>
                                    <h3>
                                        <a href="tel:3474222833">3474222833</a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>           
              <div className="slide">
                <div className="slide_img" style={{ backgroundImage: 'url(./images/slider/slider-bg-1.jpg' }}></div>
                <div className="slide__content">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 g-0">
                        <div className="slide__content--headings d-block text-md-end text-center">
                          <div className="padding_top10 padding_bottom10">
                            <h2 data-animation="fadeInDown" className="text-md-end text-theme-WhiteColor"> Cleaning</h2>
                            <h2 data-animation="fadeInDown" className="text-md-end text-theme-WhiteColor"> is now<span className="webkit-content"> [Easier]</span> </h2>
                              <div className="banner-btn text-md-left mr-0" data-animation="fadeInUp" data-delay="1.4">
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-20" href={process.env.PUBLIC_URL + '/Contact'}>GET STARTED</a> 
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>          
            </Slider>
          
        )
        
    }
}

export default Banner;