import React, {Component} from 'react';

class Logo extends Component {
    render() {
        return (

            <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="JJT Cleaning Logo" rel="home">
                <img width="270" height="100" id="logo-img" className="img-fluid" alt="logo" src="images/logo-img.png" />
            </a>
            );
    }
}

export default Logo;
