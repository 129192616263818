import React, { Component } from 'react';
import Header from '../components/layout/Header';
import { Footer } from '../components/layout/Footer';

export class Portfolio extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h2 className="title">Our Portfolio</h2>
                                    </div>
                                    <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={process.env.PUBLIC_URL + '/'} >&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0456.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_3945.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_1179.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0309.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0308.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0311.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0310.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_2439.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_3946.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0657.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0446.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_1179.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_2135.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0307.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0306.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0305.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0304.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0303.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0302.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0301.jpg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="portfolio-imagebox portfolio-imagebox-blog style2">
                                    <div className="portfolio-thumbnail">
                                        <img src="./images/portfolio/IMG_0300.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>            
            </div>
          )
      }
   }

export default Portfolio;